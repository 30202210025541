"use client";

import type { CSSProperties, FC } from "react";
import type { RouteViewType } from "../RouteView";
import { useTranslation } from "@/contexts/i18n";
import type { ToggleViewProps } from "./ToggleView.types";
import clsx from "clsx";
import styles from "./ToggleView.module.scss";

export const ToggleView: FC<ToggleViewProps> = ({ view, onToggleView }) => {
  const { t } = useTranslation();

  const options: { label: string; value: RouteViewType }[] = [
    { label: t("Map view"), value: "map" },
    { label: t("Place view"), value: "place" },
  ];
  const activeIndex = options.findIndex((option) => option.value === view);

  const handleToggle = (value: RouteViewType) => {
    if (view !== value) {
      onToggleView(value);
    }
  };

  return (
    <div
      className={clsx([styles["toggle-view"], "toggle-view"])}
      style={
        {
          "--active-index": activeIndex,
          "--options-count": options.length,
        } as CSSProperties
      }
    >
      {options.map(({ label, value }) => (
        <div
          key={value}
          className={clsx(
            styles["toggle-view__option"],
            "selection--disabled",
            view === value && styles["toggle-view__option--active"]
          )}
          onClick={() => handleToggle(value)}
        >
          {label}
        </div>
      ))}
    </div>
  );
};
