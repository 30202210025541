import type { FC, MouseEvent } from "react";
import { useCallback } from "react";
import {
  Accelerator,
  defaultAcceleratorSettings,
  EventLink,
  getAccelerator,
  Likes,
  Media,
} from "@/components/event";
import { useModal } from "@/components/common";
import { BuyBlock } from "../BuyBlock";
import { BlockProvider, useBlock } from "@/contexts/block";
import { useEvent, EventProvider } from "@/contexts/event";
import { v4 as uuidv4 } from "uuid";
import { Event } from "../Event";
import { useMediaQuery } from "@/hooks";
import { InfoIcon } from "@/components/common/Icon";
import type { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import clsx from "clsx";
import styles from "../Event.module.scss";

export const CompactView: FC<{ className?: string }> = ({ className = "" }) => {
  const { block } = useBlock<EventsBlockAttributes>();
  const { event } = useEvent();
  const { addModal } = useModal();
  const useMobileMedia = useMediaQuery(768);
  const modalId = uuidv4();

  const acceleratorSettings =
    block?.attributes?.accelerators || defaultAcceleratorSettings;
  const userViews = getAccelerator(acceleratorSettings, event?.accelerators, [
    "accelerator-user-views",
  ]);
  const bestAccelerator = getAccelerator(
    acceleratorSettings,
    event?.accelerators
  );
  const eventAttributes = {
    className: clsx([
      styles.event,
      styles["event--compact"],
      className,
      "event",
    ]),
    "data-post-id": event.id,
    ...(event.api_id && {
      "data-event-id": event.api_id,
      "data-ga-event": "view",
      "data-ga-action": `Select-${event.api_id}`,
    }),
  };

  const addEventModal = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      addModal({
        id: modalId,
        fullWidth: true,
        content: (
          <BlockProvider block={block}>
            <EventProvider
              key={event.id}
              event={event}
              isModal={true}
              view="default"
            >
              <Event
                className={`${styles["event--in-modal"]} event--in-modal`}
              />
            </EventProvider>
          </BlockProvider>
        ),
      });
    },
    [block, event, modalId, addModal]
  );

  return (
    <div {...eventAttributes}>
      <div className={styles.accelerators}>
        {!block?.attributes?.accelerators?.["accelerator-count-favorite"]
          ?.isHidden && <Likes />}
        {userViews && <Accelerator {...userViews} />}
      </div>
      <Media
        gallery={!block?.attributes?.gallery?.isHidden}
        fullscreen={false}
        swiperOptions={block?.attributes?.gallery?.swiper || {}}
        useMobileMedia={useMobileMedia}
        onClick={(e) => addEventModal(e)}
        className={styles.event__media}
        VideoProps={block?.attributes?.video || {}}
      />
      <div className={`${styles.event__info} event__info`}>
        <div
          className={`${styles.event__name} event__name`}
          onClick={(e) => addEventModal(e)}
        >
          <EventLink />
          <div className="modal-window__open">
            <InfoIcon color="var(--text-primary)" />
          </div>
        </div>
        {bestAccelerator && <Accelerator {...bestAccelerator} />}
        <div style={{ marginTop: "auto", marginBottom: "-12px" }}></div>
        <BuyBlock />
        <Likes className="d-sm-none" />
      </div>
    </div>
  );
};
