import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { EventInfo, EventInfoTabs, EventLink, Media } from "@/components/event";
import { useBlock } from "@/contexts/block";
import { isEmptyString } from "@/utils";
import { isArray, isEmptyArray } from "@/utils";
import { BuyBlock } from "../BuyBlock";
import { useMediaQuery } from "@/hooks";
import { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import clsx from "clsx";
import styles from "../Event.module.scss";

export const DefaultView: FC<{ className?: string }> = ({ className = "" }) => {
  const {
    block: { attributes },
  } = useBlock<EventsBlockAttributes>();
  const { event, isModal } = useEvent();
  const isMobileWidth = useMediaQuery(768);

  const withInfoTabs =
    event?.compare_fields?.some(
      (field) =>
        field?.title?.toLowerCase()?.includes("what's included") &&
        ((isArray(field.value) && !isEmptyArray(field.value)) ||
          (!isArray(field.value) && !isEmptyString(field.value)))
    ) && attributes?.infoTabs?.isHidden !== true;

  return (
    <div
      className={clsx([
        styles.event,
        styles["event--default"],
        "event",
        className,
      ])}
    >
      <Media
        gallery={!attributes?.gallery?.isHidden}
        fullscreen={isModal ? false : true}
        swiperOptions={attributes?.gallery?.swiper || {}}
        useMobileMedia={!isMobileWidth && isModal}
        className={styles.event__media}
        VideoProps={attributes?.video || {}}
      />
      <div className={`${styles.event__info} event__info`}>
        <div className={`${styles.event__name} event__name`}>
          <EventLink />
        </div>
        {withInfoTabs ? <EventInfoTabs /> : <EventInfo />}
        <div style={{ marginTop: "auto" }}></div>
        <BuyBlock />
      </div>
    </div>
  );
};
