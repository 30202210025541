import type { FC } from "react";
import { useBlock } from "@/contexts/block/BlockContext";
import { useEvent } from "@/contexts/event";
import { CompareFields, EventLink } from "@/components/event";
import { Media } from "@/components/event/Media";
import { BuyBlock } from "../BuyBlock";
import { Popularity } from "@/components/event/Popularity";
import { Score } from "@/components/event/Score";
import type { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import clsx from "clsx";
import styles from "../Event.module.scss";

export const CompareView: FC<{ className?: string }> = ({ className = "" }) => {
  const { block } = useBlock<EventsBlockAttributes>();
  const { event } = useEvent();

  const eventAttributes = {
    className: clsx([
      styles.event,
      styles["event--compare"],
      className,
      "event",
    ]),
    "data-post-id": event.id,
    ...(event.api_id && {
      "data-event-id": event.api_id,
      "data-ga-event": "view",
      "data-ga-action": `Compare-${event.api_id}`,
    }),
  };

  return (
    <div {...eventAttributes}>
      <Media
        gallery={false}
        fullscreen={false}
        swiperOptions={block?.attributes?.gallery?.swiper || {}}
        onClick={() => {}}
        className={styles.event__media}
        VideoProps={block?.attributes?.video || {}}
      />
      <div className={`${styles.event__info} event__info`}>
        <div className={`${styles.event__name} event__name`}>
          <EventLink />
          <Score />
        </div>
        <Popularity />
        <CompareFields excludeTitles={["likes"]} />
        <div style={{ marginTop: "auto", marginBottom: "-16px" }}></div>
        <BuyBlock />
      </div>
    </div>
  );
};
