import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block/BlockContext";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionItem,
  AccordionSummary,
} from "@/components/common";
import type { FaqBlockAttributes } from "./Faq.types";

const Faq: BlockComponent = () => {
  const {
    block: {
      id,
      attributes: { items = [] },
    },
  } = useBlock<FaqBlockAttributes>();

  const filteredItems = items.filter(
    (item) => item?.question?.trim() && item?.answer?.trim()
  );

  if (filteredItems.length < 1) {
    return null;
  }

  return (
    <div
      className="container alignwide"
      data-block-id={id}
      data-ga-event="view"
      data-ga-action="FAQ"
    >
      <BlockTitle />
      <Accordion>
        {filteredItems?.map((item, index) => (
          <AccordionItem key={`faq-item-${index}`} id={`faq-item-${index}`}>
            <AccordionSummary
              aria-controls="panel1-content"
              id={`faq-item-${index}`}
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails>{item.answer}</AccordionDetails>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

Faq.block_template = "faq";

export default Faq;
