import type { BlockComponent } from "@/types";
import type { PostListBlockAttributes } from "./PostList.types";
import { useBlock } from "@/contexts/block/BlockContext";
import { isArray, isEmptyArray } from "@/utils";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { PostItem } from "./PostItem";
import styles from "./PostList.module.scss";

const PostList: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<PostListBlockAttributes>();

  if (!isArray(attributes?.items) || isEmptyArray(attributes?.items)) {
    return null;
  }

  const featuredCount = Math.round(attributes.items.length / 3);
  const featured = attributes.items.slice(0, featuredCount) || [];
  const other =
    attributes.items.slice(
      attributes?.tiles === true ? featuredCount : 0,
      attributes.items.length
    ) || [];
  let className = attributes.items.length == 1 ? "post-list--single" : "";
  className +=
    attributes?.tiles === true && attributes.items.length > 1
      ? " post-list--tiles"
      : "";

  return (
    <div
      className={`container alignwide ${
        styles[`post-list__${block_template}`]
      } ${className}`}
      data-block-id={id}
    >
      <BlockTitle />
      <div className={styles["post-list__items"]}>
        {attributes?.tiles === true && featured?.length > 0 && (
          <div className={styles["post-list--featured"]}>
            {featured.map((post) => (
              <PostItem key={post.id} post={post} />
            ))}
          </div>
        )}
        <div className={styles["post-list--other"]}>
          {other.map((post) => (
            <PostItem key={post.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

PostList.block_template = "post_list";

export default PostList;
