"use client";

import type { FC } from "react";
import { useBlock } from "@/contexts/block";
import { isEmptyString } from "@/utils";
import type {
  BlockTitleAttributes,
  BlockTitleProps,
  TextStyleAttributes,
  TextStyles,
} from "./BlockTitle.types";
import styles from "./BlockTitle.module.scss";

const isStyleEmpty = (styleObj = {}) => Object.keys(styleObj).length === 0;

const shouldApplyTextStyling = (styleSettings: TextStyles = {}) => {
  if (isStyleEmpty(styleSettings)) {
    return false;
  }

  const { global = {}, ranges = [] } = styleSettings;
  const hasDefaultGlobalStyle =
    !global.fill ||
    global.fill === "none" ||
    !global.stroke ||
    global.stroke === "none";

  return !hasDefaultGlobalStyle || ranges.length !== 0;
};

const generateInlineStyles = (styleAttributes: TextStyleAttributes = {}) => {
  if (isStyleEmpty(styleAttributes)) {
    return "";
  }

  const styles = [];
  const fillStyleProperty = styleAttributes.stroke
    ? "-webkit-text-fill-color"
    : "color";

  if (styleAttributes.fill) {
    styles.push(`${fillStyleProperty}: ${styleAttributes.fill};`);
  }

  if (styleAttributes.fill && styleAttributes.stroke) {
    styles.push("-webkit-text-stroke-width: 2px;");
    styles.push(`-webkit-text-stroke-color: ${styleAttributes.stroke};`);
  }

  return styles.join(" ");
};

const useBlockTitle = () => {
  const {
    block: {
      attributes: { title = {} },
    },
  } = useBlock<BlockTitleAttributes>();
  const { text: rawText = "", tag: TitleTag = "div", styles = {} } = title;
  const safeText = typeof rawText === "string" ? rawText : "";
  const text = safeText.replace(/\n/g, "<br />");
  const globalStyles = generateInlineStyles(styles?.global);

  const applyStyles = (
    text: string,
    { ranges }: Pick<TextStyles, "ranges">
  ) => {
    const segments = text.split(/(<br\s*\/?>)/gi);
    let wordIndex = 1;

    const styledWords = segments.map((segment: string) => {
      if (/^<br\s*\/?>$/i.test(segment)) {
        return segment;
      }

      return segment
        .split(" ")
        .map((word) => {
          if (word.trim().length === 0) {
            return word;
          }

          let style = globalStyles;

          ranges?.forEach(({ start, end, fill, stroke }) => {
            if (wordIndex >= start && wordIndex <= end) {
              style = generateInlineStyles({
                fill: fill,
                stroke: stroke,
              });
            }
          });

          wordIndex++;

          const styleAttribute = style ? ` style="${style}"` : "";
          return `<span${styleAttribute}>${word}</span>`;
        })
        .join(" ");
    });

    return styledWords.join(" ");
  };

  const styledText = shouldApplyTextStyling(styles)
    ? applyStyles(text, styles)
    : text;

  return { TitleTag, styledText };
};

export const BlockTitle: FC<BlockTitleProps> = ({ classList = "" }) => {
  const { TitleTag, styledText } = useBlockTitle();

  if (isEmptyString(styledText)) {
    return null;
  }

  return (
    <TitleTag
      className={`${styles.block__title} block__title ${classList}`}
      dangerouslySetInnerHTML={{ __html: styledText }}
    />
  );
};
