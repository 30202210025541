import type { FC } from "react";
import { useBlock } from "@/contexts/block";
import { Gallery, Video, Image } from "@/components/common";
import { useTranslation } from "@/contexts/i18n";
import type {
  ReviewsBlockAttributes,
  ReviewItemProps,
} from "./ReviewList.types";
import { AccountCircleIcon } from "@/components/common/Icon";
import { getFirstMedia } from "@/components/common/Image/utils";
import styles from "./ReviewList.module.scss";

export const ReviewItem: FC<{ review: ReviewItemProps }> = ({ review }) => {
  const {
    block: { attributes },
  } = useBlock<ReviewsBlockAttributes>();
  const { t } = useTranslation();

  const starFilled = (review?.score / 5) * 100;
  const rating =
    attributes?.ratings?.find(
      (rating) => rating.score === Math.round(Number(review?.score))
    ) || attributes?.ratings?.find((rating) => rating?.score === 5);
  const image = getFirstMedia(review?.images);
  const attachments = review?.attachments?.desktop;

  return (
    <div className={styles.review} data-ga-event="view" data-ga-action="Rating">
      <div className={styles.review__head}>
        {image ? <Image {...image} /> : <AccountCircleIcon color="#ffffff" />}
        <div className={styles.review__name}>{t(review?.name)}</div>
        <div className={styles.review__date}>{review?.date}</div>
      </div>
      <div className={styles.review__content}>
        <div className={styles["review__info-score"]}>
          <div className={styles["review__average-score"]}>
            {review?.score || 1}
          </div>
          {rating && (
            <div className={styles["review__average-rating-name"]}>
              {t(rating?.name)}
            </div>
          )}
          <div className={styles["review__average-rating-star"]}>
            <span
              className={styles["review__star-filled"]}
              style={{
                width: `${starFilled}%`,
              }}
            />
          </div>
        </div>
        <div className={styles["review__info-text"]}>{review.text}</div>
      </div>
      {attachments && (
        <div className={styles["review__gallery"]}>
          <Gallery
            swiperOptions={{
              navigation: false,
              pagination: false,
              slidesPerView: "auto",
              autoplay: { enabled: false },
              centeredSlides: false,
              spaceBetween: 16,
              loop: false,
            }}
            fullscreen={true}
          >
            {attachments.map((item, index) =>
              item.type === "video" ? (
                <Video key={item.src} sources={[item]} />
              ) : (
                <Image
                  key={item.src}
                  {...item}
                  priority={[0, 1].includes(index)}
                  lazy={index === 0 ? false : true}
                />
              )
            )}
          </Gallery>
        </div>
      )}
    </div>
  );
};
