import type { EventAction, EventContextState } from "./EventContext.types";

export const EventReducer = (
  state: EventContextState,
  action: EventAction
): EventContextState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_EVENT":
      return { ...state, event: payload };

    default:
      return state;
  }
};
