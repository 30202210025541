import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { Gallery, Image, Video } from "@/components/common";
import {
  filterMediaWithSrc,
  setAltTextIfEmpty,
} from "@/components/common/Image/utils";
import type { Media as MediaProps } from "./Media.type";
import { isEmptyArray, uniqueBy } from "@/utils";
import { MediaItem } from "@/types";
import styles from "./Media.module.scss";

const useEventMedia = (useMobileMedia = false, allowVideo = true) => {
  const { event } = useEvent();

  const getFilteredMedia = (media: MediaItem[], offset = 0) => {
    if (isEmptyArray(media)) {
      return [];
    }

    const filtered = filterMediaWithSrc(media).map((item, idx) =>
      setAltTextIfEmpty(item, idx + offset, event?.title || "Event")
    );

    return allowVideo
      ? filtered
      : filtered.filter((item) => item.type !== "video");
  };

  const imageFull = getFilteredMedia(
    event?.image_full ? [event.image_full] : []
  );
  const gallery = getFilteredMedia(event?.gallery ?? [], imageFull.length);
  const promoMediasMobile = getFilteredMedia(
    event?.promo_medias_mobile ?? [],
    gallery.length
  );

  const media = useMobileMedia
    ? [...promoMediasMobile, ...gallery]
    : [...imageFull, ...gallery];

  return uniqueBy(media, "src");
};

export const Media: FC<MediaProps> = ({
  gallery = false,
  fullscreen = false,
  swiperOptions = {},
  useMobileMedia = false,
  className = "",
  VideoProps = { isHidden: false, attributes: {} },
  onClick = () => {},
}) => {
  const { event } = useEvent();

  const media = useEventMedia(useMobileMedia, !VideoProps?.isHidden);

  if (!media?.length) {
    return null;
  }

  return (
    <div className={`${styles.event__media} event__media ${className}`}>
      {gallery && media?.length > 1 ? (
        <Gallery
          key={event.id}
          swiperOptions={swiperOptions}
          fullscreen={fullscreen}
          onClick={(e) => onClick(e)}
        >
          {media.map((item, index) =>
            item.type === "video" ? (
              <Video
                key={item.src}
                sources={[item]}
                attributes={VideoProps.attributes}
              />
            ) : (
              <Image
                key={item.src}
                {...item}
                lazy={index === 0 ? false : true}
              />
            )
          )}
        </Gallery>
      ) : media[0].type === "video" ? (
        <Video
          key={media[0].src}
          sources={[media[0]]}
          attributes={{
            ...VideoProps.attributes,
            onClick: (e) => onClick(e),
          }}
        />
      ) : (
        <Image key={media[0].src} {...media[0]} onClick={(e) => onClick(e)} />
      )}
    </div>
  );
};
