import type { BlockComponent } from "@/types";
import type {
  PovBlockAttributes,
  PovBlockProps,
  PovItemType,
} from "./Pov.types";
import { useCallback } from "react";
import { SwiperSlide } from "swiper/react";
import { useBlock } from "@/contexts/block";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { isArray, isEmptyArray } from "@/utils";
import { v4 as uuidv4 } from "uuid";
import { useModal } from "@/components/common/Modal";
import { Slider } from "@/components/common/Slider";
import { PovItem } from "./PovItem";
import styles from "./Pov.module.scss";

const Pov: BlockComponent<PovBlockProps> = ({
  disableBlockTitle = false,
  disableVideoIcon = false,
  imageProps = {},
}) => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<PovBlockAttributes>();
  const { addModal } = useModal();
  const modalId = uuidv4();

  const addPovModal = useCallback(
    (index: number) => {
      addModal({
        id: modalId,
        content: (
          <Slider
            className={styles["swiper-container--pov-modal"]}
            options={{
              initialSlide: index,
              navigation: true,
              loop: true,
            }}
            NavigationProps={{
              variant: "rectangle",
            }}
          >
            {attributes.items.map((item: PovItemType, index: number) => (
              <SwiperSlide key={index + (item.link || "")}>
                <PovItem
                  item={item}
                  disableVideoIcon={disableVideoIcon}
                  variant="modal"
                  imageProps={imageProps}
                />
              </SwiperSlide>
            ))}
          </Slider>
        ),
      });
    },
    [attributes.items, disableVideoIcon, modalId, addModal]
  );

  if (!isArray(attributes?.items) || isEmptyArray(attributes.items)) {
    return null;
  }

  return (
    <div
      className={`${styles[block_template]} alignfull`}
      data-block-id={id}
      data-ga-event="view"
      data-ga-action="pov"
    >
      {!disableBlockTitle && (
        <div className="container">
          <BlockTitle />
        </div>
      )}
      <div className="container full-width-sm">
        <Slider
          options={{
            slidesPerView: "auto",
          }}
          NavigationProps={{
            variant: "none",
          }}
        >
          {attributes.items.map((item: PovItemType, index: number) => (
            <SwiperSlide
              key={index + (item.link || "")}
              onClick={() => addPovModal(index)}
              style={{
                aspectRatio: attributes?.aspectRatio || "93/147",
              }}
              data-ga-event="click"
              data-ga-action="Open-popup-pov"
            >
              <PovItem
                item={item}
                disableVideoIcon={disableVideoIcon}
                imageProps={imageProps}
              />
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    </div>
  );
};

Pov.block_template = "pov";

export default Pov;
