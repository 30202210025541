import type { EventsAction, EventsContextState } from "./EventsContext.types";

export const EventsReducer = (
  state: EventsContextState,
  action: EventsAction
): EventsContextState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_EVENTS":
      return { ...state, events: payload };

    case "UPDATE_EVENT_PRICE":
      const { id, price, currency, original_price } = payload.eventPrice;

      return {
        ...state,
        events: state.events.map((event) =>
          event.id === id
            ? { ...event, price, currency, original_price }
            : event
        ),
      };

    default:
      return state;
  }
};
