import type { BaseBlockAttributes } from "@/types";
import type { BlockAction, BlockContextState } from "./BlockContext.types";

export const BlockReducer = <T extends BaseBlockAttributes>(
  state: BlockContextState<T>,
  action: BlockAction<T>
): BlockContextState<T> => {
  const { type, payload } = action;

  switch (type) {
    case "SET_BLOCK":
      return { ...state, block: payload };

    default:
      return state;
  }
};
