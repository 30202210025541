"use client";

import { createContext, useContext } from "react";
import type {
  EventContextProps,
  EventContextState,
} from "./EventContext.types";

export const defaultEventState: Omit<EventContextState, "event"> = {
  view: "default",
  isModal: false,
};

export const EventContext = createContext<EventContextProps | null>(null);

export const useEvent = () => {
  const context = useContext<EventContextProps | null>(EventContext);

  if (!context) {
    throw new Error("useEvent must be used within a EventProvider");
  }

  return context;
};
