import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { isArray, isEmptyArray } from "@/utils";
import { useTranslation } from "@/contexts/i18n";
import styles from "./Tags.module.scss";
import he from "he";

export const Tags: FC = () => {
  const { event } = useEvent();
  const { t } = useTranslation();

  if (!isArray(event.tags) || isEmptyArray(event.tags)) {
    return null;
  }

  return (
    <div className={`${styles["event__tags"]} event__tags`}>
      {event.tags.map((tag, index) => (
        <div
          key={index}
          className={`${styles["event__tags__tag"]} event__tags__tag`}
        >
          {he.decode(t(tag.title.rendered))}
        </div>
      ))}
    </div>
  );
};
