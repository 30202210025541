import type { FC } from "react";
import type { BenefitItemProps } from "./BenefitList.types";
import Image from "next/image";
import { useTranslation } from "@/contexts/i18n";
import { getFirstMedia } from "@/components/common/Image/utils";
import styles from "./BenefitList.module.scss";

export const BenefitItem: FC<BenefitItemProps> = ({
  item,
  backgroundCard,
  boxShadow,
  imagesIsHidden,
}) => {
  const { t } = useTranslation();

  const image = getFirstMedia(item?.images, "desktop", item.title);

  return (
    <div
      className={`${styles["benefits__benefit"]} ${boxShadow}`}
      style={{ backgroundColor: backgroundCard }}
    >
      {!imagesIsHidden && image && <Image {...image} />}
      {item?.title && (
        <div className={styles["benefits__benefit__name"]}>{t(item.title)}</div>
      )}
      {item?.text && (
        <div className={styles["benefits__benefit__description"]}>
          {t(item.text)}
        </div>
      )}
    </div>
  );
};
