import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { useBlock } from "@/contexts/block";
import { ScrollWrapper } from "@/components/common";
import { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import { Excerpt, Popularity, Tags } from "@/components/event";

export const EventInfo: FC = () => {
  const {
    block: { attributes },
  } = useBlock<EventsBlockAttributes>();
  const { isModal } = useEvent();
  const tagsIsHidden = attributes?.tags?.isHidden === true;

  return isModal ? (
    <ScrollWrapper
      containerSelector=".event__info"
      subtractionSelectors={[
        ".event__name",
        ".buy-block",
        ".swiper.swiper-tabs",
      ]}
    >
      {!tagsIsHidden && <Tags />}
      <Excerpt />
      <Popularity scope="modal" />
    </ScrollWrapper>
  ) : (
    <div className="event__about">
      {!tagsIsHidden && <Tags />}
      <Excerpt />
      <Popularity />
    </div>
  );
};
