"use client";

import type { FC } from "react";
import { useCallback, useEffect, useRef } from "react";
import { useEvent } from "@/contexts/event";
import { slideToggle } from "@/utils";
import styles from "./Excerpt.module.scss";
import { injectReadMore } from "@/utils";
import { useTranslation } from "@/contexts/i18n";

export const Excerpt: FC = () => {
  const {
    event: { content = "" },
  } = useEvent();
  const excerptRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (!target.closest(".read-more")) {
        return;
      }

      const excerptElement = excerptRef.current;
      const moreContent = excerptElement?.querySelector(".more-content");
      const readMore = excerptElement?.querySelector(".read-more");

      if (!moreContent || !readMore) {
        return;
      }

      const isExpanded = readMore.getAttribute("aria-expanded") === "true";
      let text = "Read more";

      if (isExpanded) {
        readMore.setAttribute("aria-expanded", "false");
        text = readMore.getAttribute("data-more-text") || "Read more";
      } else {
        target.setAttribute("aria-expanded", "true");
        text = readMore.getAttribute("data-less-text") || "Read less";
      }

      readMore.innerHTML = t(text);

      slideToggle(moreContent as HTMLElement);
    },
    [t]
  );

  useEffect(() => {
    const excerptElement = excerptRef.current;

    if (excerptElement) {
      excerptElement.addEventListener("click", handleClick);
    }

    return () => {
      if (excerptElement) {
        excerptElement.removeEventListener("click", handleClick);
      }
    };
  }, [handleClick]);

  return (
    <div
      ref={excerptRef}
      className={`${styles.event__excerpt} event__excerpt`}
      dangerouslySetInnerHTML={{ __html: injectReadMore(content) }}
      data-ga-event="view"
      data-ga-action="Select-About"
    />
  );
};
