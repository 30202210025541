import type { FC } from "react";
import { CompareFields, EventInfo } from "@/components/event";
import { useBlock } from "@/contexts/block";
import { ScrollWrapper, Slider } from "@/components/common";
import { useTranslation } from "@/contexts/i18n";
import { SwiperSlide } from "swiper/react";
import { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";

export const EventInfoTabs: FC = () => {
  const {
    block: { attributes },
  } = useBlock<EventsBlockAttributes>();
  const { t } = useTranslation();

  const mainTabName = attributes?.infoTabs?.tab_main || "About";
  const additionalTabName = attributes?.infoTabs?.tab_main || "Includes";

  return (
    <Slider
      tabs={[t(mainTabName), t(additionalTabName)]}
      NavigationProps={{
        variant: "none",
      }}
    >
      <SwiperSlide>
        <EventInfo />
      </SwiperSlide>
      <SwiperSlide>
        <ScrollWrapper
          containerSelector=".event__info"
          subtractionSelectors={[
            ".event__name",
            ".buy-block",
            ".swiper.swiper-tabs",
          ]}
        >
          <CompareFields includeTitles={["what's included"]} />
        </ScrollWrapper>
      </SwiperSlide>
    </Slider>
  );
};
