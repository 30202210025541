import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { useTranslation } from "@/contexts/i18n";
import { isEmptyString } from "@/utils";
import type { ArticleBlockAttributes } from "./Article.types";
import styles from "./Article.module.scss";

const Article: BlockComponent = () => {
  const {
    block: { id, attributes },
  } = useBlock<ArticleBlockAttributes>();
  const { t } = useTranslation();

  if (!attributes?.content || isEmptyString(attributes?.content)) {
    return null;
  }

  return (
    <div className="container alignwide" data-block-id={id}>
      <BlockTitle />
      <div
        className={`${styles.article__content} article__content`}
        dangerouslySetInnerHTML={{
          __html: t(attributes.content),
        }}
      />
    </div>
  );
};

Article.block_template = "article";

export default Article;
