"use client";

import { request } from "./request";

export interface SubscriptionSuccessResponse {
  message: string;
}

export interface SubscriptionErrorResponse {
  code: string;
  message: string;
  data?: any;
}

export type SubscriptionResult =
  | SubscriptionSuccessResponse
  | SubscriptionErrorResponse;

export const subscribe = async (email: string): Promise<SubscriptionResult> => {
  return await request("/wp-json/satellite/v1/subscribe", {
    method: "POST",
    body: JSON.stringify({ email }),
  });
};
