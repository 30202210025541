import type { FC } from "react";
import { isEmptyString, isArray, isEmptyArray } from "@/utils";
import { useEvent } from "@/contexts/event";
import { useTranslation } from "@/contexts/i18n";
import { CompareFieldProps, CompareFieldsProps } from "./CompareFields.types";
import styles from "./CompareFields.module.scss";
import clsx from "clsx";

export const CompareField: FC<{
  id: CompareFieldProps["id"];
  className?: string;
}> = ({ id, className = "" }) => {
  const { event } = useEvent();
  const field = event?.compare_fields?.find(
    (field: CompareFieldProps) => field.id == id
  );
  const { t } = useTranslation();

  if (
    !field ||
    (isArray(field.value) && isEmptyArray(field.value)) ||
    (!isArray(field.value) && isEmptyString(field.value))
  ) {
    return null;
  }

  return (
    <div
      className={clsx([
        styles["compare-field"],
        styles[`compare-field--${field.type}`],
        "compare-field",
        `compare-field--${field.type}`,
        className,
      ])}
    >
      {!isEmptyString(field.title) && (
        <div
          className={clsx([
            styles["compare-field__title"],
            "compare-field__title",
          ])}
        >
          {t(field.title)}:
        </div>
      )}
      {isArray(field.value) ? (
        <ul>
          {field.value.map((item: string, index: number) => (
            <li key={index}>{t(item)}</li>
          ))}
        </ul>
      ) : (
        <span>{t(field.value)}</span>
      )}
    </div>
  );
};

export const CompareFields: FC<CompareFieldsProps> = ({
  includeIds = [],
  includeTitles = [],
  excludeIds = [],
  excludeTitles = [],
}) => {
  const { event } = useEvent();

  if (isEmptyArray(event?.compare_fields)) {
    return null;
  }

  const fields = event?.compare_fields?.filter(
    (field: CompareFieldProps) =>
      (!isEmptyArray(includeIds) && includeIds.includes(field.id)) ||
      (!isEmptyArray(includeTitles) &&
        includeTitles.includes(field.title.toLowerCase())) ||
      (!isEmptyArray(excludeIds) && !excludeIds.includes(field.id)) ||
      (!isEmptyArray(excludeTitles) &&
        !excludeTitles.includes(field.title.toLowerCase()))
  );

  if (!fields) {
    return null;
  }

  return fields.map(
    ({ id }: { id: CompareFieldProps["id"] }, index: number) => {
      const className = [
        index === 0 ? "compare-field--first" : "",
        index === fields.length - 1 ? "compare-field--last" : "",
      ]
        .filter(Boolean)
        .join(" ");

      return <CompareField key={id} id={id} className={className} />;
    }
  );
};
