import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { GoogleMap, Gallery, Video, Image } from "@/components/common";
import { ToggleView } from "../ToggleView";
import type { Place, RouteViewProps, RouteViewType } from "./RouteView.types";
import { prepareResponsiveMediaList } from "@/components/common/Image/utils";
import { isEmptyArray } from "@/utils";
import styles from "../../TripRoute.module.scss";

const parseCoordinate = (
  coordinate: string | number | undefined
): number | null => {
  if (coordinate === undefined || coordinate === null) {
    return null;
  }

  if (typeof coordinate === "number") {
    return coordinate;
  }

  if (typeof coordinate === "string") {
    const trimmed = coordinate.trim();

    if (trimmed === "") {
      return null;
    }

    const parsed = Number(trimmed);

    return isNaN(parsed) ? null : parsed;
  }

  return null;
};

const RouteViewPlace: FC<{ place: Place }> = ({ place }) => {
  const images = prepareResponsiveMediaList(place?.images);

  if (isEmptyArray(images.desktop)) {
    return null;
  }

  return (
    <Gallery
      swiperOptions={{
        loop: false,
        slidesPerView: 1,
        autoplay: false,
      }}
      fullscreen={false}
      navigation="circle"
    >
      {images.desktop.map((item, index) =>
        item.type === "video" ? (
          <Video key={item.src} sources={[item]} />
        ) : (
          <Image
            key={item.src}
            {...item}
            priority={[0, 1].includes(index)}
            lazy={index === 0 ? false : true}
          />
        )
      )}
    </Gallery>
  );
};

const RouteViewMap: FC<{
  markers: Place[];
  activeMarker: number;
  onActiveMarkerChange: (index: number) => void;
}> = ({ markers, activeMarker, onActiveMarkerChange }) => (
  <GoogleMap
    markers={markers}
    activeMarker={activeMarker}
    activeMarkerCallback={onActiveMarkerChange}
  />
);

export const RouteView: FC<RouteViewProps> = ({
  activeMarker = 0,
  places = [],
  place,
  setActiveMarker,
}) => {
  const [view, setView] = useState<RouteViewType>("map");

  const mapAvailable = useMemo(() => {
    const lat = parseCoordinate(place?.latitude);
    const lng = parseCoordinate(place?.longitude);

    return !place?.views?.map?.isHidden && lat !== null && lng !== null;
  }, [place]);

  const placeAvailable = useMemo(
    () =>
      !place?.views?.place?.isHidden &&
      Array.isArray(place?.images?.desktop) &&
      place.images.desktop.length > 0,
    [place]
  );

  const handleToggleView = (newView: RouteViewType) => {
    setView(newView);
  };

  useEffect(() => {
    if (view === "map" && !mapAvailable && placeAvailable) {
      setView("place");
    } else if (view === "place" && !placeAvailable && mapAvailable) {
      setView("map");
    }
  }, [view, mapAvailable, placeAvailable]);

  return (
    <div className={styles["place-view"]}>
      {mapAvailable && placeAvailable && (
        <ToggleView view={view} onToggleView={handleToggleView} />
      )}
      {view === "place" && placeAvailable && <RouteViewPlace place={place} />}
      {view === "map" && mapAvailable && (
        <RouteViewMap
          markers={places}
          activeMarker={activeMarker}
          onActiveMarkerChange={setActiveMarker}
        />
      )}
    </div>
  );
};
