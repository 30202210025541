import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "@/contexts/i18n";
import type { GoToProps } from "./GoTo.types";
import { useEvents } from "@/contexts/events";
import styles from "./GoTo.module.scss";

export const GoTo: FC<GoToProps> = ({
  targetClassName,
  tooltipText,
  svgIcon,
  ...props
}) => {
  const { events } = useEvents();
  const { t } = useTranslation();
  const [targetBlock, setTargetBlock] = useState<HTMLElement | null>(null);

  const scroll = useCallback(() => {
    if (targetBlock) {
      const headerHeight = document.querySelector("header")?.offsetHeight || 0;
      const y =
        targetBlock.getBoundingClientRect().top +
        window.scrollY -
        headerHeight -
        20;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [targetBlock]);

  useEffect(() => {
    const block = document.querySelector(`.${targetClassName}`);

    setTargetBlock(block as HTMLElement);
  }, [events, targetClassName]);

  if (!targetBlock) {
    return null;
  }

  return (
    <div className={`${styles["go-to"]} go-to`} onClick={scroll} {...props}>
      {svgIcon}
      <span className={`${styles["go-to__tooltip"]} go-to__tooltip`}>
        {t(tooltipText)}
      </span>
    </div>
  );
};
