import type { FC } from "react";
import { useState } from "react";
import { StepList, StepItem } from "@/components/common";
import { useBlock } from "@/contexts/block";
import { useTranslation } from "@/contexts/i18n";
import locationActive from "@/public/images/icons/location_active.svg";
import { RouteView } from "../RouteView";
import { AccessTimeIcon } from "@/components/common/Icon";
import type { TripRouteBlockAttributes } from "../../TripRoute.types";
import type { RouteProps } from "./Route.types";
import { isEmptyString } from "@/utils";
import type { StaticImageData } from "next/image";
import Image from "next/image";
import styles from "../../TripRoute.module.scss";

export const Route: FC<RouteProps> = ({ route }) => {
  const { block } = useBlock<TripRouteBlockAttributes>();
  const [activeMarker, setActiveMarker] = useState(0);
  const { t } = useTranslation();

  const duration = route?.duration || "";
  const address = route?.address || "";
  const locationActiveIcon = locationActive as StaticImageData;

  return (
    <>
      {(!isEmptyString(duration) || !isEmptyString(address)) && (
        <div className={styles["route-info"]}>
          {!isEmptyString(duration) && (
            <div className={styles["route-duration"]}>
              <AccessTimeIcon />
              <span>
                {t("Route duration:")} <span>{t(duration)}</span>
              </span>
            </div>
          )}
          {!isEmptyString(address) && (
            <div className={styles["route-address"]}>{t(address)}</div>
          )}
        </div>
      )}
      <div
        className={`${styles["route-places"]} ${
          block?.attributes?.display_map != false ? styles["with-map"] : ""
        }`}
      >
        <StepList current={activeMarker} vertical>
          {route?.items?.map((place, index) => {
            const name = place?.name || "";
            const description = place?.description || "";

            if (isEmptyString(name)) {
              return null;
            }

            return (
              <StepItem
                key={name + description + index}
                title={t(name)}
                description={t(description)}
                iconActive={
                  <Image
                    src={locationActiveIcon.src}
                    width={locationActiveIcon.width}
                    height={locationActiveIcon.height}
                    alt="Location Active"
                  />
                }
                onClick={() => setActiveMarker(index)}
              />
            );
          })}
        </StepList>
        <RouteView
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
          places={route?.items || []}
          place={route?.items?.[activeMarker]}
        />
      </div>
    </>
  );
};
