import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block/BlockContext";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { isEmptyString } from "@/utils";
import { HeroImage } from "./HeroImage";
import { HeroVideo } from "./HeroVideo";
import { HeroButtons } from "./HeroButtons";
import type { HeroBlockAttributes } from "./Hero.types";
import clsx from "clsx";
import styles from "./Hero.module.scss";

const Hero: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<HeroBlockAttributes>();

  const alignItems = !isEmptyString(attributes?.alignItems)
    ? attributes.alignItems
    : "center";
  const justifyContent = !isEmptyString(attributes?.justifyContent)
    ? attributes.justifyContent
    : "center";

  return (
    <div
      className={clsx([styles[block_template], "alignfull"])}
      style={{
        justifyContent: justifyContent,
      }}
      data-block-id={id}
    >
      <HeroImage />
      <HeroVideo />
      <div
        className={`container ${styles.container}`}
        style={{
          alignItems: alignItems,
        }}
      >
        <BlockTitle classList={`block__title ${styles.block__title}`} />
        <HeroButtons />
      </div>
    </div>
  );
};

Hero.block_template = "hero";

export default Hero;
