import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block/BlockContext";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { useTranslation } from "@/contexts/i18n";
import type { YouTubeBlockAttributes } from "./YouTube.types";
import styles from "./styles.module.scss";

const YouTube: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<YouTubeBlockAttributes>();
  const { t } = useTranslation();

  const videoId = attributes?.video_id;
  const displayType = attributes?.display_type
    ? `youtube--${attributes.display_type}`
    : "youtube--column";

  return (
    <div
      className={`container alignwide ${styles[block_template]}`}
      data-block-id={id}
      data-ga-event="view"
      data-ga-action="Youtubevideoblock"
    >
      <BlockTitle />
      <div className={styles[displayType]}>
        <div className={styles["youtube__video"]}>
          <iframe
            className={styles["youtube__iframe"]}
            width="420"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}`}
            loading="lazy"
          />
        </div>
        {attributes?.text && (
          <div
            className={styles["youtube__text"]}
            dangerouslySetInnerHTML={{
              __html: t(attributes.text),
            }}
          />
        )}
      </div>
    </div>
  );
};

YouTube.block_template = "youtube";

export default YouTube;
