import type { CSSProperties, FC } from "react";
import { EventListProps } from "../EventList.types";
import { Event } from "@/components/event";
import { EventProvider } from "@/contexts/event";
import clsx from "clsx";
import styles from "../EventList.module.scss";

export const CompactView: FC<Omit<EventListProps, "view">> = ({ events }) => {
  const maxRows = 3;
  const rowCount = Math.min(maxRows, Math.ceil(events.length / maxRows));
  const columnCount = Math.ceil(events.length / rowCount);

  return (
    <div className={clsx("container", "full-width-sm")}>
      <div
        className={clsx(
          styles["event-list"],
          styles["event-list--compact"],
          "event-list",
          "event-list--compact"
        )}
        style={{ "--rows": rowCount } as CSSProperties}
      >
        {events.map((event, index) => {
          const currentColumn = Math.floor(index / rowCount);
          const isLastColumn = currentColumn === columnCount - 1;

          return (
            <EventProvider
              key={`${event.id}-${event.title}`}
              event={event}
              view="compact"
            >
              <Event className={`${isLastColumn ? "last-column" : ""}`} />
            </EventProvider>
          );
        })}
      </div>
    </div>
  );
};
