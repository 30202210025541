"use client";

import type { FC } from "react";
import { useBlock } from "@/contexts/block";
import { Image } from "@/components/common/Image";
import { isEmptyArray, isEmptyString } from "@/utils";
import type { HeroBlockAttributes } from "./Hero.types";
import styles from "./Hero.module.scss";
import { prepareResponsiveMediaList } from "@/components/common/Image/utils";

export const HeroImage: FC = () => {
  const {
    block: { attributes },
  } = useBlock<HeroBlockAttributes>();
  const images = prepareResponsiveMediaList(attributes?.images);
  const isMobileAvailable = !isEmptyString(images.mobile?.[0]?.src);
  const isTabletAvailable = !isEmptyString(images.tablet?.[0]?.src);
  const isDesktopAvailable =
    !isEmptyArray(images.desktop) && !isEmptyString(images.desktop?.[0]?.src);

  if (!isDesktopAvailable) {
    return null;
  }

  return (
    <picture data-ga-event="view" data-ga-action="Hero-picture">
      {isMobileAvailable && (
        <source srcSet={images.mobile[0].src} media="(max-width: 480px)" />
      )}
      {isTabletAvailable && (
        <source srcSet={images.tablet[0].src} media="(max-width: 768px)" />
      )}
      <Image
        className={styles.hero__image}
        priority={true}
        {...images.desktop[0]}
      />
    </picture>
  );
};
