"use client";

import type { Event } from "@/types";
import { request } from "./request";

export const find = async (queryParams = {}): Promise<Partial<Event>[]> => {
  return await request("/wp-json/wp/v2/events", {
    queryParams,
    cache: "force-cache",
    next: {
      revalidate: 3600,
      tags: ["events"],
    },
  });
};
