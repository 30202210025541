import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { useTranslation } from "@/contexts/i18n";
import type { ReviewsBlockAttributes } from "./ReviewList.types";
import { ReviewItem } from "./ReviewItem";
import styles from "./ReviewList.module.scss";
import { isArray, isEmptyArray, isEmptyString } from "@/utils";

const Reviews: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<ReviewsBlockAttributes>();
  const { t } = useTranslation();

  if (!isArray(attributes?.items) || isEmptyArray(attributes?.items)) {
    return null;
  }

  const averageScore = Number(attributes?.average_score || 5);
  const reviewsCount =
    !isNaN(Number(attributes?.reviews_count)) &&
    Number(attributes.reviews_count) > 0
      ? Number(attributes.reviews_count)
      : attributes?.items?.length;
  const starFilled = (averageScore / 5) * 100;
  const rating = attributes?.ratings?.find(
    (rating) => rating.score === Math.round(averageScore)
  );
  const ratingName = rating?.name || "Excellent";
  const hasHeadTitle = !isEmptyString(attributes?.head_title);
  const hasHeadDescription = !isEmptyString(attributes?.head_description);

  return (
    <div
      className={`container alignwide ${styles[`reviews--${block_template}`]}`}
      data-block-id={id}
    >
      <BlockTitle />
      <div className={styles["reviews__summary"]}>
        <div className={styles["reviews__summary-score"]}>
          <div className={styles["reviews__average-score"]}>{averageScore}</div>
          <div className={styles["reviews__average-rating"]}>
            <div className={styles["reviews__average-rating-name"]}>
              {t(ratingName)}
            </div>
            <div className={styles["reviews__average-rating-star"]}>
              <span
                className={styles["reviews__star-filled"]}
                style={{
                  width: `${starFilled}%`,
                }}
              ></span>
            </div>
          </div>
        </div>
        <div className={styles["reviews__summary-text"]}>
          {hasHeadTitle && (
            <div className={styles["reviews__summary-text-title"]}>
              <span>{attributes.head_title}</span>
              <div
                className={styles["reviews__summary-count"]}
              >{`${reviewsCount} ${t("Reviews")}`}</div>
            </div>
          )}
          {hasHeadDescription && (
            <div className={styles["reviews__summary-text-description"]}>
              {attributes.head_description}
            </div>
          )}
        </div>
      </div>
      <div className={styles["reviews__list-title"]}>
        {t("Reviews")}
        <span className={styles["reviews__list-count"]}> ({reviewsCount})</span>
      </div>
      <div className={styles["reviews__list"]}>
        {attributes?.items?.map((item, index) => (
          <ReviewItem key={index} review={item} />
        ))}
      </div>
    </div>
  );
};

Reviews.block_template = "reviews";

export default Reviews;
