import type { FC } from "react";
import { useTranslation } from "@/contexts/i18n";
import { isEmptyString } from "@/utils";
import { Video } from "@/components/common/Video";
import { Image } from "@/components/common/Image";
import { ScrollWrapper } from "@/components/common/ScrollWrapper";
import { VideoIcon, InstagramIcon } from "@/components/common/Icon";
import type { PovItemProps } from "./Pov.types";
import { getFirstMedia } from "@/components/common/Image/utils";
import styles from "./Pov.module.scss";
import clsx from "clsx";

const PovDescription: FC<{ description?: string }> = ({ description }) => {
  const { t } = useTranslation();

  if (!description || isEmptyString(description)) {
    return null;
  }

  const formattedDescription = t(description).replace(/\n/g, "<br>");

  return (
    <ScrollWrapper
      containerSelector={`.${styles["pov-info"]}`}
      subtractionSelectors={[styles["pov-info__username"]]}
      additionalSubtraction={24}
    >
      <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
    </ScrollWrapper>
  );
};

const PovUsername: FC<{
  username?: string;
  link?: string;
  variant?: "default" | "modal";
}> = ({ username = "", link = "", variant = "default" }) => {
  const { t } = useTranslation();
  const UsernameTag = !isEmptyString(link) ? "a" : "span";

  if (isEmptyString(username)) {
    return null;
  }

  return (
    <UsernameTag
      {...(!isEmptyString(link) ? { href: link } : {})}
      className={clsx([
        styles["pov__username"],
        styles[`pov__username--${variant}`],
      ])}
    >
      {variant === "default" && <InstagramIcon />}
      <span>{t(username)}</span>
    </UsernameTag>
  );
};

export const PovItem: FC<PovItemProps> = ({
  item,
  disableVideoIcon,
  variant = "default",
  imageProps,
}) => {
  const imageItem = getFirstMedia(item.images);
  const videoItem = getFirstMedia(item.videos);

  const imageSrc = imageItem?.src;
  const videoSrc = videoItem?.src;

  const hasVideo = Boolean(videoSrc && videoSrc.length > 0);
  const hasImage = Boolean(imageSrc && imageSrc.length > 0);

  return (
    <>
      <div className={styles["pov-media"]}>
        {!hasVideo && hasImage && imageItem && (
          <Image {...imageItem} {...imageProps} lazy />
        )}
        {hasVideo && videoItem && (
          <>
            {!disableVideoIcon && (
              <VideoIcon
                className={styles["svg-union"]}
                style={{ color: "white" }}
              />
            )}
            <Video
              sources={item.videos?.desktop ?? []}
              attributes={{ poster: hasImage ? imageSrc : "" }}
            />
          </>
        )}
      </div>
      {variant === "default" ? (
        <PovUsername username={item?.username} link={item?.link} />
      ) : (
        <div className={styles["pov-info"]}>
          <PovUsername
            username={item?.username}
            link={item?.link}
            variant={variant}
          />
          <PovDescription description={item.description} />
        </div>
      )}
    </>
  );
};
