export interface ApiError extends Error {
  response?: { data?: { errors?: [] } };
}

export const buildQueryString = (
  queryParams: Record<string, string | number | boolean | undefined>
): string => {
  const qs = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      qs.append(key, String(value));
    }
  });

  return qs.toString() ? `?${qs}` : "";
};

export const logErrorResponse = (error: ApiError) => {
  try {
    if (error.response?.data?.errors) {
      console.error(JSON.stringify(error.response.data));
    } else {
      console.error("Unknown error:", error.message);
    }
  } catch {
    console.error(JSON.stringify(error));
  }
};
