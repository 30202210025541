"use client";

import type { FC } from "react";
import { useCallback, useEffect } from "react";
import type { GoogleAnalyticsProps } from "./GoogleAnalytics.type";
import { GoogleTag, GoogleTagManager } from "@/components/analytics";
import { useApp } from "@/contexts/app";
import { useEvents } from "@/contexts/events";
import { useCurrencies } from "@/contexts/currencies";
import { isArray } from "@/utils";

interface GaCustomEventData {
  event: string;
  [key: string]: unknown;
}

const getGtmData = (
  variables?: Array<{ name: string; value: string }>
): Record<string, string> => {
  const data: Record<string, string> = {};

  if (!variables) {
    return data;
  }

  for (const { name, value } of variables) {
    data[name] = value;
  }

  return data;
};

export const GoogleAnalytics: FC = () => {
  const { app } = useApp();
  const { currency } = useCurrencies();
  const { events } = useEvents();

  const { trackerIds, gtmIds, gtmVariables }: GoogleAnalyticsProps = {
    trackerIds: app?.google_settings?.tracker_ids || [],
    gtmIds: app?.google_settings?.gtm_ids || [],
    gtmVariables: app?.google_settings?.gtm_variables || [],
  };

  const push = useCallback(
    (data: Record<string, unknown>) => {
      if (typeof window === "undefined") {
        return;
      }

      if (!window.dataLayer) {
        window.dataLayer = [];
      }

      const variablesData = getGtmData(gtmVariables);
      const enrichedData = {
        ...data,
        ...variablesData,
      };

      window.dataLayer.push(enrichedData);
    },
    [gtmVariables]
  );

  useEffect(() => {
    window.pushToDataLayer = push;
  }, [push]);

  useEffect(() => {
    const handleViewItem = () => {
      if (
        !isArray(events) ||
        events.length === 0 ||
        !currency.attributes.code
      ) {
        return;
      }

      const { code: currencyCode } = currency.attributes;

      const validEvents = events.filter((event) => event.api_id && event.title);

      if (validEvents.length === 0) {
        return;
      }

      const items = validEvents.map((event, index) => ({
        item_id: event.api_id,
        item_name: event.title,
        currency: currencyCode,
        index: index + 1,
      }));

      const itemViewLayer = {
        event: "view_item",
        ecommerce: {
          currency: currencyCode,
          items: items,
        },
        event_id: validEvents[0].api_id,
      };

      push(itemViewLayer);
    };

    const handleClickEvents = (event: Event) => {
      const target = event.target as HTMLElement;
      const element = target.closest("[data-ga-event='click']");

      if (!element) {
        return;
      }

      const customEventData = element.getAttribute("data-ga-custom-event");

      if (customEventData) {
        try {
          const parsedData = JSON.parse(customEventData) as GaCustomEventData;

          if (
            parsedData &&
            typeof parsedData === "object" &&
            parsedData.event
          ) {
            push(parsedData);
            return;
          }
        } catch (error) {
          console.error("invalid json in data-ga-custom-event:", error);
        }
      }

      const action = element.getAttribute("data-ga-action");

      if (!action) {
        return;
      }

      push({ event: "click", action });
    };

    const handleViewEvents = (): void => {
      type CallbackFunction = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver,
        name: string
      ) => void;

      const callback: CallbackFunction = (entries, observer, name) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }

          push({ event: "view", action: name });
          observer.unobserve(entry.target);
        });
      };

      const createObserver = (
        target: Element,
        callback: CallbackFunction,
        name: string
      ): void => {
        const observer = new IntersectionObserver(
          (entries, observer) => callback(entries, observer, name),
          { root: null, threshold: 1 }
        );
        observer.observe(target);
      };

      const elements: NodeListOf<Element> = document.querySelectorAll(
        "[data-ga-event='view']"
      );

      elements.forEach((element) => {
        const action = element.getAttribute("data-ga-action");

        if (!action) {
          return;
        }

        createObserver(element, callback, action);
      });
    };

    handleViewItem();
    handleViewEvents();
    document.addEventListener("click", handleClickEvents);

    return () => {
      document.removeEventListener("click", handleClickEvents);
    };
  }, [currency.attributes, events, push]);

  return (
    <>
      <GoogleTag trackerIds={trackerIds} />
      <GoogleTagManager gtmIds={gtmIds} />
    </>
  );
};
