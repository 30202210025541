"use client";

import type { FC } from "react";
import type { ButtonProps } from "@/components/common";
import { ButtonList } from "@/components/common";
import { useBlock } from "@/contexts/block";
import { isArray, isEmptyArray, isEmptyString } from "@/utils";
import type { HeroBlockAttributes } from "./Hero.types";

export const HeroButtons: FC = () => {
  const {
    block: {
      attributes: { buttons },
    },
  } = useBlock<HeroBlockAttributes>();

  if (!isArray(buttons?.items) || isEmptyArray(buttons.items)) {
    return null;
  }

  const justifyButtons = !isEmptyString(buttons?.align) ? buttons.align : "end";
  const buttonsWithGAAttributes = buttons.items.map((button: ButtonProps) => ({
    ...button,
    attributes: {
      "data-ga-event": "click",
      "data-ga-action": "Hero-Buybutton",
    },
  }));

  return (
    <ButtonList
      buttons={buttonsWithGAAttributes}
      style={{
        justifyContent: justifyButtons,
      }}
    />
  );
};
