import type { FC } from "react";
import Script from "next/script";
import type { GoogleTagProps } from "./GoogleTag.type";
import { isArray, isEmptyArray } from "@/utils";

const normalizeTrackerIds = (trackerIds: string | string[]): string[] => {
  if (typeof trackerIds === "string") {
    return trackerIds.split(",").map((id) => id.trim());
  }

  return trackerIds;
};

export const GoogleTag: FC<GoogleTagProps> = ({ trackerIds }) => {
  const trackerIdArray = normalizeTrackerIds(trackerIds);

  if (!isArray(trackerIdArray) || isEmptyArray(trackerIdArray)) {
    return null;
  }

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${trackerIdArray[0]}`}
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {/* eslint-disable indent */}
        {`
          function gtag(){window.pushToDataLayer(arguments);}
          gtag('js', new Date());
          ${trackerIdArray
            .map(
              (id) => `
              gtag('config', '${id}', {
                page_path: window.location.pathname,
              });
            `
            )
            .join("")}
        `}
        {/* eslint-enable indent */}
      </Script>
    </>
  );
};
