import type { FC } from "react";
import { DefaultView } from "./views/DefaultView";
import { CompactView } from "./views/CompactView";
import { CompareView } from "./views/CompareView";
import { useEvent } from "@/contexts/event";

export const Event: FC<{ className?: string }> = (props) => {
  const { view } = useEvent();

  switch (view) {
    case "compact":
      return <CompactView {...props} />;
    case "compare":
      return <CompareView {...props} />;
    case "default":
    default:
      return <DefaultView {...props} />;
  }
};
