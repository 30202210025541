import type { FC } from "react";
import { useBlock } from "@/contexts/block";
import { Price } from "@/components/event/Price";
import { GoTo } from "@/components/common/GoTo";
import {
  Accelerator,
  BuyButton,
  defaultAcceleratorSettings,
  getAccelerator,
} from "@/components/event";
import { useEvent } from "@/contexts/event";
import { isEmptyString } from "@/utils";
import { useTranslation } from "@/contexts/i18n";
import { BarChartIcon } from "@/components/common/Icon";
import type { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import styles from "./Event.module.scss";

const BuyBlockTitle: FC<{ text?: string }> = ({ text = "" }) => {
  const { view } = useEvent();
  const { t } = useTranslation();

  if (view !== "compare") {
    return null;
  }

  if (isEmptyString(text)) {
    return null;
  }

  return <div className={styles["buy-block__title"]}>{t(text)}</div>;
};

const AdditionalButtons: FC = ({}) => {
  const { isModal, view } = useEvent();
  const { block } = useBlock<EventsBlockAttributes>();

  if (
    view === "compare" ||
    isModal ||
    block?.attributes?.additionalButtons?.isHidden
  ) {
    return null;
  }

  return (
    <GoTo
      targetClassName="event-list--compare"
      tooltipText="Compare"
      svgIcon={<BarChartIcon />}
      data-ga-event="click"
      data-ga-action="Compare-Buytickets"
    />
  );
};

export const BuyBlock: FC = () => {
  const { event, isModal, view } = useEvent();
  const { block } = useBlock<EventsBlockAttributes>();

  const acceleratorSettings =
    block?.attributes?.accelerators || defaultAcceleratorSettings;
  const bestAccelerator = getAccelerator(
    acceleratorSettings,
    event?.accelerators
  );

  let buyBlockSettings = block?.attributes?.buyBlock;

  if (isModal && block?.attributes?.buyBlockInModal) {
    buyBlockSettings = block?.attributes?.buyBlockInModal;
  }

  if (buyBlockSettings?.isHidden) {
    return null;
  }

  return (
    <div className={`${styles["buy-block"]} buy-block`}>
      <div className={styles["buy-block__info"]}>
        <BuyBlockTitle text={buyBlockSettings?.text} />
        {!buyBlockSettings?.price?.isHidden && <Price />}
        {["default", "compare"].includes(view) && bestAccelerator && (
          <Accelerator {...bestAccelerator} />
        )}
      </div>
      <div className={styles["buy-block__actions"]}>
        {!buyBlockSettings?.buyButton?.isHidden && <BuyButton />}
        <AdditionalButtons />
      </div>
    </div>
  );
};
