import type { FC } from "react";
import { EventListProps } from "../EventList.types";
import { Event } from "@/components/event";
import { EventProvider } from "@/contexts/event";
import styles from "../EventList.module.scss";

export const DefaultView: FC<Omit<EventListProps, "view">> = ({ events }) => {
  return (
    <div className="container">
      <div
        className={`${styles["event-list"]} ${styles["event-list--default"]}`}
      >
        {events.map((event, index) => (
          <EventProvider
            key={`${event.id}-${event.title}`}
            event={event}
            view="default"
          >
            <Event key={index} />
          </EventProvider>
        ))}
      </div>
    </div>
  );
};
