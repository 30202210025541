import type { FC } from "react";
import type { PostItemProps } from "./PostList.types";
import { Image } from "@/components/common";
import { isEmptyString } from "@/utils";
import styles from "./PostList.module.scss";

export const PostItem: FC<{ post: PostItemProps }> = ({ post }) => {
  const featuredMedia = post?.featured_media;

  if (!post?.id) {
    return null;
  }

  return (
    <div className={styles.post}>
      {featuredMedia && !isEmptyString(featuredMedia.src) && (
        <a href={post.link}>
          <Image {...featuredMedia} />
        </a>
      )}
      <div className={styles["post__post-info"]}>
        <a href={post.link} className={styles["post__post-title"]}>
          {post.title.rendered}
        </a>
        <div
          className={styles["post__post-content"]}
          dangerouslySetInnerHTML={{
            __html: post.content.rendered,
          }}
        />
      </div>
    </div>
  );
};
