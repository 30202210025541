"use client";

import { createContext, useContext } from "react";
import type {
  EventsContextProps,
  EventsContextState,
} from "./EventsContext.types";

export const defaultEventsState: EventsContextState = {
  events: [],
};

export const EventsContext = createContext<EventsContextProps | null>(null);

export const useEvents = () => {
  const context = useContext<EventsContextProps | null>(EventsContext);

  if (!context) {
    throw new Error("useEvents must be used within a EventsProvider");
  }

  return context;
};
