import { useCallback, useMemo, useReducer } from "react";
import { BlockContext } from "./BlockContext";
import { BlockReducer } from "./BlockReducer";
import type {
  BlockContextProps,
  BlockProviderProps,
} from "./BlockContext.types";
import { useAfterInitialRender } from "@/hooks";
import type { BaseBlockAttributes, Block } from "@/types";

export const BlockProvider = <T extends BaseBlockAttributes>({
  block,
  children,
}: BlockProviderProps<T>) => {
  const [state, dispatch] = useReducer(BlockReducer<T>, { block });

  const setBlock = useCallback((block: Block<T>) => {
    dispatch({ type: "SET_BLOCK", payload: block });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setBlock,
    }),
    [state, setBlock]
  );

  const getContextValue = (): BlockContextProps<BaseBlockAttributes> => {
    return contextValue as unknown as BlockContextProps<BaseBlockAttributes>;
  };

  const callback = useCallback(() => {
    setBlock(block);
  }, [block, setBlock]);

  useAfterInitialRender(callback, [block]);

  return (
    <BlockContext.Provider value={getContextValue()}>
      {children}
    </BlockContext.Provider>
  );
};
