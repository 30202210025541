"use client";

import { createContext, useContext } from "react";
import type { BlockContextProps } from "./BlockContext.types";
import type { BaseBlockAttributes } from "@/types";

export const BlockContext =
  createContext<BlockContextProps<BaseBlockAttributes> | null>(null);

export const useBlock = <T extends BaseBlockAttributes>() => {
  const context = useContext(BlockContext) as BlockContextProps<T> | null;

  if (!context) {
    throw new Error("useBlock must be used within a BlockProvider");
  }

  return context;
};
