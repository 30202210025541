"use client";

import { getCookie } from "cookies-next";
import { buildQueryString, logErrorResponse } from "../utils/request";

export interface ApiError extends Error {
  response?: { data?: { errors?: [] } };
}

export const getCommonHeaders = (): Record<string, string> => {
  const referer = document.referrer || "";
  const locale = (getCookie("user_lang") as string) || "";
  const currency = (getCookie("user_currency") as string) || "";
  const additionalCookies = document.cookie || "";
  const hasIsPromotionalSite = additionalCookies
    .split(";")
    .some((c) => c.trim().startsWith("is_promotional_site="));

  const cookie = hasIsPromotionalSite
    ? additionalCookies
    : `is_promotional_site=1; ${additionalCookies}`;

  return {
    Referer: referer,
    "Accept-Language": locale,
    "Accept-Currency": currency,
    Cookie: cookie,
  };
};

export const getHost = (): string => {
  const protocol = window.location.protocol || "http:";
  const host = window.location.host || "localhost";

  return `${protocol}//${host}`.replace(/\/$/, "");
};

export const request = async <T>(
  endpoint: string,
  opts: {
    method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
    body?: BodyInit | null;
    headers?: Record<string, string>;
    queryParams?: Record<string, string | number | boolean | undefined>;
    cache?: RequestCache;
    next?: {
      revalidate?: false | number;
      tags?: string[];
    };
  } = {}
): Promise<T> => {
  const {
    method = "GET",
    body,
    headers = {},
    queryParams = {},
    cache = "no-store",
    next = {
      revalidate: 0,
      tags: [],
    },
  } = opts;
  const host = getHost();
  const hdrs = { ...headers, ...getCommonHeaders() };

  if (hdrs["Accept-Currency"]) {
    queryParams["_currency"] = hdrs["Accept-Currency"];
  }

  const queryString = buildQueryString(queryParams);
  const url = `${host}${endpoint}${queryString}`;

  try {
    const response = await fetch(url, {
      method,
      credentials: "include",
      headers: hdrs,
      body,
      cache,
      next,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      const errorMessage =
        errorData.message ||
        `${method} ${url} failed with status ${response.status}`;

      throw new Error(errorMessage);
    }

    return (await response.json()) as T;
  } catch (error) {
    logErrorResponse(error as ApiError);
    throw error;
  }
};
