import type { FC } from "react";
import { GoogleTagManager as NextGoogleTagManager } from "@next/third-parties/google";
import type { GoogleTagManagerProps } from "./GoogleTagManager.type";
import { isArray, isEmptyArray } from "@/utils";

export const GoogleTagManager: FC<GoogleTagManagerProps> = ({ gtmIds }) => {
  const gtmIdArray =
    typeof gtmIds === "string"
      ? gtmIds.split(",").map((id) => id.trim())
      : gtmIds;

  if (!isArray(gtmIdArray) || isEmptyArray(gtmIdArray)) {
    return null;
  }

  return gtmIdArray.map((id) => <NextGoogleTagManager key={id} gtmId={id} />);
};
