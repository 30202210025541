import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block";
import { Slider } from "@/components/common";
import { SwiperSlide } from "swiper/react";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { useTranslation } from "@/contexts/i18n";
import { isArray, isEmptyArray } from "@/utils";
import type { InfoTabsBlockAttributes } from "./InfoTabs.types";
import styles from "./InfoTabs.module.scss";

const InfoTabs: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<InfoTabsBlockAttributes>();
  const { t } = useTranslation();

  const filteredItems = attributes?.items?.filter(
    (item) => item?.name?.length > 0 && item?.content?.length > 0
  );

  if (!isArray(filteredItems) || isEmptyArray(filteredItems)) {
    return null;
  }

  return (
    <div
      className={`container alignwide ${styles["info-tabs"]} ${styles[block_template]} full-width-xs `}
      data-block-id={id}
    >
      <BlockTitle />
      <Slider
        className={`swiper-container--info-tabs ${
          attributes?.box_shadow !== false
            ? `${styles["box-shadow"]} box-shadow`
            : ""
        }`}
        tabs={filteredItems.map((item) => t(item.name)) || []}
        classNameTabs="swiper-tabs--rounded"
        options={{ autoHeight: true, navigation: { enabled: true } }}
        NavigationProps={{
          variant: "circle",
        }}
      >
        {filteredItems.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className={styles["info-tabs__content"]}
              dangerouslySetInnerHTML={{
                __html: t(item.content),
              }}
            />
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};

InfoTabs.block_template = "info_tabs";

export default InfoTabs;
