import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { isArray, isEmptyArray } from "@/utils";
import { BenefitItem } from "./BenefitItem";
import type { BenefitsBlockAttributes } from "./BenefitList.types";
import styles from "./BenefitList.module.scss";

const BenefitList: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<BenefitsBlockAttributes>();

  const {
    background_card = "#ffffff",
    box_shadow = true,
    images = {},
    items = [],
  } = attributes;
  const boxShadow =
    box_shadow !== false ? styles["benefits__benefit--box-shadow"] : "";
  const imagesIsHidden = images?.isHidden ?? true;

  if (!isArray(items) || isEmptyArray(items)) {
    return null;
  }

  return (
    <div
      className={`container alignwide ${styles[block_template]}`}
      data-block-id={id}
    >
      <BlockTitle />
      <div className={styles["benefits__list"]}>
        {items.map((item, index: number) => (
          <BenefitItem
            key={index}
            item={item}
            backgroundCard={background_card}
            boxShadow={boxShadow}
            imagesIsHidden={imagesIsHidden}
          />
        ))}
      </div>
    </div>
  );
};

BenefitList.block_template = "benefits";

export default BenefitList;
