import type { BlockComponent } from "@/types";
import { Slider } from "@/components/common";
import { SwiperSlide } from "swiper/react";
import { useBlock } from "@/contexts/block";
import { useTranslation } from "@/contexts/i18n";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { isArray, isEmptyArray } from "@/utils";
import { Route } from "./components";
import type { TripRouteBlockAttributes } from "./TripRoute.types";
import styles from "./TripRoute.module.scss";

const TripRoute: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<TripRouteBlockAttributes>();
  const filteredItems = attributes?.items?.filter((item) => !item.isHidden);
  const { t } = useTranslation();

  if (!isArray(filteredItems) || isEmptyArray(filteredItems)) {
    return null;
  }

  return (
    <div
      className={`container alignwide ${styles[block_template]}`}
      data-block-id={id}
    >
      <BlockTitle />
      <Slider
        tabs={filteredItems.map((item) => t(item.name)) || []}
        classNameTabs="swiper-tabs--rounded"
        options={{ allowTouchMove: false, autoHeight: true }}
        NavigationProps={{
          variant: "none",
        }}
      >
        {filteredItems.map((item, index) => (
          <SwiperSlide key={index}>
            <Route route={item} />
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};

TripRoute.block_template = "trip_route";

export default TripRoute;
