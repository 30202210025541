"use client";

import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { EventContext, defaultEventState } from "./EventContext";
import { EventReducer } from "./EventReducer";
import type {
  EventContextState,
  EventProviderProps,
} from "./EventContext.types";
import { useAfterInitialRender } from "@/hooks";
import type { Event } from "@/types";

export const EventProvider: FC<EventProviderProps> = ({
  event,
  view,
  isModal,
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      event,
      view,
      isModal,
    }).filter(([, value]) => value !== undefined)
  );

  const [state, dispatch] = useReducer(EventReducer, {
    ...(defaultEventState as EventContextState),
    ...overrides,
  });

  const setEvent = useCallback((event: Partial<Event>) => {
    dispatch({ type: "SET_EVENT", payload: event });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setEvent,
    }),
    [state, setEvent]
  );

  const callback = useCallback(() => {
    setEvent(event);
  }, [event, setEvent]);

  useAfterInitialRender(callback, [event]);

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};
