import type { FC } from "react";
import { EventListProps } from "./EventList.types";
import { DefaultView } from "./views/DefaultView";
import { CompactView } from "./views/CompactView";
import { CompareView } from "./views/CompareView";

export const EventList: FC<EventListProps> = ({ view, events }) => {
  switch (view) {
    case "compact":
      return <CompactView events={events} />;
    case "compare":
      return <CompareView events={events} />;
    case "default":
    default:
      return <DefaultView events={events} />;
  }
};
