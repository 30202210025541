"use client";

import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { useTranslation } from "@/contexts/i18n";
import styles from "./Price.module.scss";

export const Price: FC = () => {
  const {
    event: { price, currency, original_price },
  } = useEvent();
  const { t } = useTranslation();

  const safePrice = price ?? 0;
  const safeOriginalPrice = original_price ?? 0;

  return (
    <div className={`${styles.event__price} event__price`}>
      {safeOriginalPrice > 0 && (
        <div
          className={`${styles["event__price-discount-label"]} event__price-discount-label`}
        >
          <span
            className={`${styles["event__price-label"]} event__price-label`}
          >
            {t("Price from:")}{" "}
          </span>
          <span
            className={`${styles["event__price-original-amount"]} ${styles["event__price-original-amount--crossed"]}`}
          >
            {`${safeOriginalPrice} ${currency}`}
          </span>
        </div>
      )}
      {safePrice > 0 && (
        <div
          className={`${styles["event__price-final-amount"]} event__price-final-amount`}
        >
          {`${safePrice} ${currency}`}
        </div>
      )}
    </div>
  );
};
