"use client";

import type { FC } from "react";
import { useBlock } from "@/contexts/block";
import { useMediaQuery } from "@/hooks";
import { Video } from "@/components/common";
import type { HeroBlockAttributes } from "./Hero.types";
import styles from "./Hero.module.scss";

export const HeroVideo: FC = () => {
  const {
    block: { attributes },
  } = useBlock<HeroBlockAttributes>();
  const isMobileWidth = useMediaQuery(576);

  if (
    !attributes ||
    !attributes.images ||
    !attributes.videos ||
    !attributes.images.desktop ||
    !attributes.videos.desktop
  ) {
    return null;
  }

  const image =
    isMobileWidth &&
    attributes.images.mobile &&
    attributes.images.mobile.length > 0
      ? attributes.images.mobile[0]
      : attributes.images.desktop[0];

  const video =
    isMobileWidth &&
    attributes.videos.mobile &&
    attributes.videos.mobile.length > 0
      ? attributes.videos.mobile[0]
      : attributes.videos.desktop[0];

  if (!video || !video.src) {
    return null;
  }

  return (
    <Video
      key={video.src}
      attributes={{
        className: styles.hero__video,
        poster: image.src,
        "data-ga-event": "view",
        "data-ga-action": "Hero-video",
      }}
      sources={[video]}
    />
  );
};
