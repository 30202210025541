import type { BlockComponent } from "@/types";
import type { ImageProps } from "next/image";
import { useBlock } from "@/contexts/block";
import { Gallery, Image, Video } from "@/components/common";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { useMediaQuery } from "@/hooks";
import { isArray } from "@/utils";
import type { ImageGalleryBlockAttributes } from "./ImageGallery.types";
import styles from "./ImageGallery.module.scss";

const ImageGallery: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<ImageGalleryBlockAttributes>();

  const isMobileWidth = useMediaQuery(576);
  const aspectRatio = attributes?.aspectRatio || "16/9";
  const gallery = isArray(attributes?.images?.desktop)
    ? attributes.images.desktop
    : [];
  const galleryMobile = isArray(attributes?.images?.mobile)
    ? attributes.images.mobile
    : [];
  const images =
    isMobileWidth && galleryMobile.length > 0 ? galleryMobile : gallery;

  const filteredMedia = images.filter(
    (image: ImageProps) =>
      typeof image.src === "string" && image.src.trim().length > 0
  );

  if (filteredMedia.length < 1) {
    return null;
  }

  return (
    <div
      className={`container alignwide ${styles[block_template]}`}
      data-block-id={id}
    >
      <BlockTitle />
      <Gallery
        swiperOptions={attributes?.swiper || {}}
        fullscreen={false}
        navigation="circle"
        aspectRatio={aspectRatio}
      >
        {filteredMedia.map((item, index) =>
          item.type === "video" ? (
            <Video key={item.src} sources={[item]} />
          ) : (
            <Image
              key={item.src}
              {...item}
              priority={[0, 1].includes(index)}
              lazy={index === 0 ? false : true}
            />
          )
        )}
      </Gallery>
    </div>
  );
};

ImageGallery.block_template = "image_gallery";

export default ImageGallery;
