import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { useTranslation } from "@/contexts/i18n";
import { Button } from "@/components/common";

export const BuyButton: FC = () => {
  const {
    event: { api_id, title, buy_button },
  } = useEvent();
  const { t } = useTranslation();

  if (!buy_button?.text) {
    return null;
  }

  const text = t(buy_button?.text)?.trim();
  let gaCustomEvent = {};

  if (api_id) {
    gaCustomEvent = {
      "data-ga-event": "click",
      "data-ga-custom-event": JSON.stringify({
        event: "ga4_events.custom",
        ga4_events: {
          event_name: "click_buy",
          id: api_id,
          id_name: title,
        },
      }),
    };
  }

  if (!text) {
    return null;
  }

  return (
    <Button
      link={buy_button?.url}
      text={text}
      style="success"
      target={buy_button?.target}
      attributes={gaCustomEvent}
    />
  );
};
