import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block/BlockContext";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { Image } from "@/components/common/Image";
import { ButtonList, ButtonProps } from "@/components/common";
import type { BannerBlockAttributes } from "./Banner.types";
import { getFirstMedia } from "@/components/common/Image/utils";
import styles from "./Banner.module.scss";

const Banner: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<BannerBlockAttributes>();

  const text = attributes?.text ?? "";
  const image = getFirstMedia(attributes?.images);

  const buttonsWithGAAttributes = attributes?.buttons?.map(
    (button: ButtonProps) => {
      const action = `Appblock-${button.text.replace(/[^a-z0-9]/gi, "")}`;

      return {
        ...button,
        attributes: {
          "data-ga-event": "click",
          "data-ga-action": action,
        },
      };
    }
  );

  return (
    <div className="container alignwide" data-block-id={id}>
      <div className={styles[block_template]}>
        <BlockTitle />
        {text?.length > 0 && (
          <div className={styles.banner__text}>{attributes?.text}</div>
        )}
        {image && <Image {...image} />}
        <ButtonList buttons={buttonsWithGAAttributes || []} />
      </div>
    </div>
  );
};

Banner.block_template = "banner";

export default Banner;
