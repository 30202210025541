import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import styles from "./Score.module.scss";

export const Score: FC = () => {
  const {
    event: { score },
  } = useEvent();

  if (Number(score) <= 0) {
    return null;
  }

  return (
    <span className={`${styles["event__score"]} event__score`}>{score}</span>
  );
};
