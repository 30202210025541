import type { FC } from "react";
import { useMemo } from "react";
import { isEmptyString } from "@/utils";
import { useEvent } from "@/contexts/event";
import { isArray, isEmptyArray } from "@/utils";
import styles from "./styles.module.scss";
import { useTranslation } from "@/contexts/i18n";
import {
  AcceleratorProps,
  AcceleratorsSettings,
  AcceleratorType,
} from "./Accelerators.types";
import { Image } from "@/components/common";
import { VisibilityIcon } from "@/components/common/Icon";
import type { BuyButton } from "@/types";

export const generatePromocodeLink = (
  type: string,
  symbols: string,
  buyButton?: BuyButton
): string => {
  if (type !== "promocode-accelerate" || !buyButton) {
    return "#";
  }

  try {
    const url = new URL(buyButton.url);
    url.searchParams.append("promo-code", symbols);

    return url.toString();
  } catch {
    return "#";
  }
};

export const Accelerator: FC<AcceleratorProps> = ({
  type,
  symbols,
  title,
  color,
  icon,
}) => {
  const {
    event: { buy_button },
  } = useEvent();
  const { t } = useTranslation();

  const promocodeLink = useMemo(() => {
    if (!type || isEmptyString(title?.rendered)) {
      return "#";
    }

    return generatePromocodeLink(type, symbols, buy_button);
  }, [type, symbols, title?.rendered, buy_button]);

  if (!type || isEmptyString(title?.rendered)) {
    return null;
  }

  const renderContent = () => {
    const iconElement = icon ? <Image {...icon} /> : null;
    const contentStyle = color ? { color } : undefined;

    if (type === "promocode-accelerate") {
      return (
        <a
          href={promocodeLink}
          className={`${styles.accelerator} ${styles[type]} accelerator ${type}`}
          style={contentStyle}
        >
          {iconElement}
          {`${t(title.rendered)} - ${symbols}`}
        </a>
      );
    }

    return (
      <div
        className={`${styles.accelerator} ${styles[type]} accelerator ${type}`}
        style={contentStyle}
      >
        {iconElement}
        {type === "accelerator-user-views" && (
          <VisibilityIcon color="var(--text-primary)" />
        )}
        <span>{t(title.rendered)}</span>
      </div>
    );
  };

  return renderContent();
};

export const getAccelerator = (
  settings: AcceleratorsSettings,
  accelerators?: AcceleratorProps[],
  types?: AcceleratorType[]
): AcceleratorProps | null => {
  if (settings?.isHidden === true) {
    return null;
  }

  if (!isArray(accelerators) || isEmptyArray(accelerators)) {
    return null;
  }

  const defaultTypes: AcceleratorType[] = [
    "promocode-accelerate",
    "discount",
    "accelerator-custom-text",
    "accelerator-best-price",
  ];
  const activeTypes = types || defaultTypes;

  for (const type of activeTypes) {
    const accelerator = accelerators.find(
      (a) => a?.type === type && settings?.[a?.type]?.isHidden !== true
    );

    if (accelerator && accelerator.title?.rendered) {
      return accelerator;
    }
  }

  return null;
};

export const defaultAcceleratorSettings: AcceleratorsSettings = {
  isHidden: false,
  "promocode-accelerate": { isHidden: false },
  discount: { isHidden: false },
  "accelerator-custom-text": { isHidden: false },
  "accelerator-best-price": { isHidden: false },
  "accelerator-user-views": { isHidden: false },
  "accelerator-count-favorite": { isHidden: false },
  "accelerator-user-avatars": { isHidden: false },
};
