import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { useTranslation } from "@/contexts/i18n";
import { LikesProps } from "./Likes.types";
import { FavoriteIcon } from "@/components/common/Icon";
import { isEmptyString } from "@/utils";
import acceleratorStyles from "../Accelerators/styles.module.scss";

export const Likes: FC<LikesProps> = ({ className = "" }) => {
  const { event } = useEvent();
  const { t } = useTranslation();
  const likes = event?.compare_fields?.find(
    (field) => field.title.toLowerCase() == "likes"
  );

  if (!likes?.value || isEmptyString(likes?.value)) {
    return null;
  }

  return (
    <div
      className={`${acceleratorStyles.accelerator} ${acceleratorStyles["accelerator-count-favorite"]} accelerator accelerator-count-favorite ${className}`}
    >
      <FavoriteIcon color="var(--error-main)" />
      <span>
        {likes.value} {t("liked it")}
      </span>
    </div>
  );
};
