import type { FC } from "react";
import { EventListProps } from "../EventList.types";
import { Event } from "@/components/event";
import { EventProvider } from "@/contexts/event";
import { Slider } from "@/components/common";
import { SwiperSlide } from "swiper/react";
import { useBlock } from "@/contexts/block";
import type { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import clsx from "clsx";
import styles from "../EventList.module.scss";

export const CompareView: FC<Omit<EventListProps, "view">> = ({ events }) => {
  const { block } = useBlock<EventsBlockAttributes>();

  return (
    <div className="container full-width-sm">
      <Slider
        className={clsx(
          styles["event-list"],
          styles["event-list--compare"],
          "event-list",
          "event-list--compare"
        )}
        options={block?.attributes?.container?.swiper || {}}
        NavigationProps={{
          variant: "circle",
        }}
      >
        {events.map((event) => (
          <SwiperSlide key={`${event?.id}-${event?.title}`}>
            <EventProvider event={event} view="compare">
              <Event />
            </EventProvider>
          </SwiperSlide>
        ))}
      </Slider>
    </div>
  );
};
