import { useState } from "react";
import type { BlockComponent } from "@/types";
import { useBlock } from "@/contexts/block";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { useTranslation } from "@/contexts/i18n";
import type { SubscribeBlockAttributes } from "./Subscribe.types";
import { api } from "@/api/client";
import { toast, ToastContainer } from "react-toastify";
import styles from "./Subscribe.module.scss";

const Subscribe: BlockComponent = () => {
  const {
    block: { id, block_template, attributes },
  } = useBlock<SubscribeBlockAttributes>();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async () => {
    if (!email) {
      toast.error(t("Please enter your email address"));
      return;
    }

    setLoading(true);
    try {
      const response = await api.subscription.subscribe(email);
      toast.success(response.message || t("Subscription successful!"));
      setEmail("");
    } catch (error: any) {
      const errorMsg =
        error.message || t("Failed to subscribe. Please try again.");
      toast.error(errorMsg);
    }
    setLoading(false);
  };

  return (
    <div
      className="container alignwide"
      data-block-id={id}
      data-ga-event="view"
      data-ga-action="subscribe"
    >
      <div className={`${styles[block_template]}`}>
        <BlockTitle />
        {attributes?.text && (
          <div className={styles.text}>{attributes.text}</div>
        )}
        <div className={styles["subscribe-form"]}>
          <div className={styles["input-container"]}>
            <input
              type="email"
              name="email"
              placeholder={t("Enter your email here")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button onClick={handleSubscribe} disabled={loading}>
            <span>{loading ? t("Subscribing...") : t("Subscribe now")}</span>
          </button>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

Subscribe.block_template = "subscribe";

export default Subscribe;
