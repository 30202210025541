import type { BlockComponent, Event } from "@/types";
import { useBlock } from "@/contexts/block";
import { useEvents } from "@/contexts/events";
import { EventList } from "@/components/event";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { isArray, isEmptyArray } from "@/utils";
import type { EventsBlockAttributes } from "./Events.types";

const sanitizeEvents = (
  items: string[],
  events: Partial<Event>[]
): Partial<Event>[] => {
  if (!isArray(items)) {
    return [];
  }

  return items
    .map((item: string) => {
      const id = parseInt(item, 10);

      return events.find((event) => event.id === id);
    })
    .filter(
      (event): event is Partial<Event> =>
        Boolean(event) &&
        typeof event === "object" &&
        !!event.id &&
        !!event.title
    );
};

const Events: BlockComponent = () => {
  const { block } = useBlock<EventsBlockAttributes>();
  const { events } = useEvents();

  const view = block?.attributes?.container?.type ?? "default";
  const validItems = sanitizeEvents(block?.attributes?.items, events);

  if (!isArray(validItems) || isEmptyArray(validItems)) {
    return null;
  }

  if (view === "compare" && validItems.length < 2) {
    return null;
  }

  return (
    <div
      className="alignfull"
      data-block-id={block?.id}
      data-ga-event="view"
      data-ga-action={`${view === "compare" ? "Compareblock" : "Select-block"}`}
    >
      <div className="container">
        <BlockTitle />
      </div>
      <EventList view={view} events={validItems} />
    </div>
  );
};

Events.block_template = "events";

export default Events;
