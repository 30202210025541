import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import he from "he";

export const EventLink: FC = () => {
  const {
    event: { title, page },
  } = useEvent();

  if (!title) {
    return null;
  }

  return <a href={page?.url}>{he.decode(title)}</a>;
};
