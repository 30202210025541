"use client";

import type { FC } from "react";
import { useCallback, useMemo, useReducer } from "react";
import { EventsContext, defaultEventsState } from "./EventsContext";
import { EventsReducer } from "./EventsReducer";
import type { EventsProviderProps } from "./EventsContext.types";
import { useAfterInitialRender } from "@/hooks";
import { useCurrencies } from "@/contexts/currencies";
import { useApp } from "@/contexts/app";
import nProgress from "nprogress";
import { api } from "@/api/client";
import type { Event } from "@/types";
import { getHost } from "@/api/client/request";
import { isWhiteLabelDomain } from "@/utils";

export const EventsProvider: FC<EventsProviderProps> = ({
  events,
  children,
}) => {
  const overrides = Object.fromEntries(
    Object.entries({
      events,
    }).filter(([, value]) => value !== undefined)
  );
  const [state, dispatch] = useReducer(EventsReducer, {
    ...defaultEventsState,
    ...overrides,
  });
  const { isLoading, setIsLoading } = useApp();
  const { currency } = useCurrencies();

  const setEvents = useCallback((events: Event[]) => {
    dispatch({ type: "SET_EVENTS", payload: events });
  }, []);

  const updateEventPrice = useCallback((eventPrice: Partial<Event>) => {
    dispatch({
      type: "UPDATE_EVENT_PRICE",
      payload: { eventPrice },
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      setEvents,
      updateEventPrice,
    }),
    [state, setEvents, updateEventPrice]
  );

  const updatePrice = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    nProgress.start();

    try {
      const eventIds = state?.events?.map((event) => event.id) || [];
      const host = getHost();
      const events: Partial<Event>[] = await api.events.find({
        include: eventIds.filter(Boolean).join(",") || "",
        orderby: "include",
        per_page: 100,
        _fields: "id,price,currency,original_price",
        price_scope: !isWhiteLabelDomain(host) ? "site" : "white-label",
      });

      events.forEach((eventPrice) => {
        updateEventPrice(eventPrice);
      });
    } catch (error) {
      console.error("error fetching data:", error);
    } finally {
      setIsLoading(false);
      nProgress.done();
    }
  }, [isLoading, state?.events, setIsLoading, updateEventPrice]);

  useAfterInitialRender(updatePrice, [currency.id]);

  return (
    <EventsContext.Provider value={contextValue}>
      {children}
    </EventsContext.Provider>
  );
};
