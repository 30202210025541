import type { FC } from "react";
import { useBlock } from "@/contexts/block";
import { useEvent } from "@/contexts/event";
import {
  Accelerator,
  defaultAcceleratorSettings,
  getAccelerator,
} from "../Accelerators";
import { Likes } from "../Likes";
import { People } from "../People";
import type { EventsBlockAttributes } from "@/components/blocks/Events/Events.types";
import clsx from "clsx";
import styles from "./Popularity.module.scss";

export const Popularity: FC<{ scope?: "default" | "modal" }> = ({
  scope = "default",
}) => {
  const { block } = useBlock<EventsBlockAttributes>();
  const { event } = useEvent();

  const baseAcceleratorsSettings =
    scope === "modal"
      ? block?.attributes?.acceleratorsInModal
      : block?.attributes?.accelerators;
  const acceleratorsSettings =
    baseAcceleratorsSettings || defaultAcceleratorSettings;
  const isBlockHidden = acceleratorsSettings?.isHidden;
  const isLikesHidden =
    acceleratorsSettings?.["accelerator-count-favorite"]?.isHidden;
  const isPeopleHidden =
    acceleratorsSettings?.["accelerator-user-avatars"]?.isHidden;

  const userViews = getAccelerator(acceleratorsSettings, event?.accelerators, [
    "accelerator-user-views",
  ]);

  const shouldDisplay =
    !isBlockHidden && (!isLikesHidden || !isPeopleHidden || userViews);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <div className={clsx([styles["event__popularity"], "event__popularity"])}>
      {!isLikesHidden && <Likes />}
      {userViews && <Accelerator {...userViews} />}
      {!isPeopleHidden && <People />}
    </div>
  );
};
