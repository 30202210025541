import type { FC } from "react";
import { useEvent } from "@/contexts/event";
import { isArray, isEmptyArray } from "@/utils";
import { Image } from "@/components/common";
import type { MediaItem } from "@/types";
import styles from "./People.module.scss";

export const People: FC = () => {
  const { event } = useEvent();

  if (!isArray(event?.people) || isEmptyArray(event?.people)) {
    return null;
  }

  return (
    <div
      className={`${styles["event__people"]} event__people hidden-scrollbar`}
    >
      {event.people.map((image: MediaItem) => (
        <Image key={`event-${event.id}-${image.src}`} {...image} />
      ))}
    </div>
  );
};
