import type { BlockComponent } from "@/types";
import { isArray, isEmptyArray } from "@/utils";
import { useBlock } from "@/contexts/block";
import { BlockTitle } from "@/components/blocks/BlockTitle";
import { ButtonList as ButtonListComponent } from "@/components/common";
import type { ButtonsBlockAttributes } from "./ButtonList.type";
import styles from "./ButtonList.module.scss";

const ButtonList: BlockComponent = () => {
  const {
    block: { id, attributes },
  } = useBlock<ButtonsBlockAttributes>();

  if (!isArray(attributes?.buttons) || isEmptyArray(attributes.buttons)) {
    return null;
  }

  return (
    <div
      className={`container alignwide ${styles["buttons"]}`}
      data-block-id={id}
    >
      <BlockTitle />
      <ButtonListComponent buttons={attributes.buttons} />
    </div>
  );
};

ButtonList.block_template = "buttons";

export default ButtonList;
