"use client";

import { createElement } from "react";
import type { BaseBlockAttributes, Block as BlockProps } from "@/types";
import { BlockProvider } from "@/contexts/block";
import { blocksRegistry } from "@/components/blocks/blocksRegistry";

export const Block = <T extends BaseBlockAttributes>({
  block,
}: {
  block: BlockProps<T>;
}) => {
  if (!block || block.attributes?.isHidden) {
    return null;
  }

  const Component = blocksRegistry[block.block_template];

  if (!Component) {
    return null;
  }

  return (
    <BlockProvider key={block.id} block={block}>
      {createElement(Component)}
    </BlockProvider>
  );
};
