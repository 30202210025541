import type { BlockComponent } from "@/types";

const blocksRegistry: Record<string, BlockComponent> = {};
const context = require.context("./", true, /index\.(t|j)sx?$/);

context.keys().forEach((key: string) => {
  const mod = context<{ default: BlockComponent }>(key);
  const component = mod.default;

  if (component && component.block_template) {
    blocksRegistry[component.block_template] = component;
  }
});

export { blocksRegistry };
